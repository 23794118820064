/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable no-duplicate-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useRef,
  useState,
  useEffect,
  ChangeEvent
  // DragEventHandler,
} from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import {
  Box,
  Table,
  Button,
  Dialog,
  Divider,
  Popover,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  IconButton,
  Typography,
  DialogActions,
  DialogContent,
  FormHelperText,
  TableContainer
} from '@mui/material';
import * as XLSX from 'xlsx';
import {
  // FilterIcon,
  ProfileIcon,
  ExcelFileIcon,
  ExportPlanIcon,
  PlusCircleIcon,
  DownloadPdfIcon
} from '../../../../../../utils/Icons';
import { toast } from 'react-toastify';
// import UserFilter from './UserFilter';
import { LoadingButton } from '@mui/lab';
import ExportButton from './ExportButton';
import ExportPopover from './ExportPopover';
import { API } from '../../../../../../api';
import InviteUserDialog from '../InviteUserDialog';
import ProgramUserDetails from './ProgramUserDetails';
import ProgramUserTableRow from './ProgramUserTableRow';
import ProgramInvitedUsers from './ProgramInvitedUsers';
import { useLoader } from '../../../../components/useLoader';
import { adminPanel } from '../../../../../../utils/muiStyle';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import MyPagination from '../../../../components/MyPagination';
import upload from '../../../../../../assets/images/upload.svg';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  IGroupUser,
  fetchGroupUsers,
  groupUsersActions
} from '../../../../../../redux/slices/group-users/groupUsersSlice';
import LevelQuestionAnswers from '../../Dialogs/LevelQuestionAnswers';
// import excelimg from "../../../../../../assets/images/excelimg.svg";
import bulkupload from '../../../../../../assets/images/bulkupload.svg';
import downloadblue from '../../../../../../assets/images/downloadblue.svg';
import { FilterSearch } from '../../../../components/filter/FilterProperty';
import ProgramUsersLoader from '../../../../../../Loader/ProgramUsersLoader';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { IAllTaskData } from '../../../../../../redux/slices/goals-tasks/getTasksSlice';
import CommonModalHeader from '../../../../../../components/DialogUi/CommonModalHeader';
import { fetchAllGroupsList } from '../../../../../../redux/slices/getAllGroupsListSlice';
import useResponsiveScreenSwitch from '../../../../../../utils/useResponsiveScreenSwitch';
// import LinearProgressWithLabel from '../../../Matches/components/LinearProgressWithLabel';
import { getGoalProgressShowOrNot, unixToHumanReadable } from '../../../../components/common';
import { getUserDetails } from '../../../../../../utils/orgName';

const ProgramUsers = () => {
  const grpId = useParams().id || '';
  const orgId = useParams().orgId || '';
  const groupUsers = useAppSelector((state) => state.groupUsers);
  const [showProfile, setShowProfile] = useState(false);
  const groupDetails = useAppSelector((state) => state.groupDetails.data);
  const showCompany = groupDetails?.userOptionalFields?.company ? true : false;
  const [activeUserId, setActiveUserId] = useState('');
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [anchorExport, setAnchorExport] = React.useState<HTMLButtonElement | null>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openUploadUsers, setOpenUploadUsers] = React.useState(false);
  const [bulkUserLoading, setBulkUserLoading] = useState(false);
  // const [openBulkUsers,setOpenBulkUsers]=React.useState(false);
  // const [openFilter, setopenFilter] = useState(false);
  // const handleopenFilter = (flag: boolean) => setopenFilter(flag);
  const [scrollToUserCall, setScrollToUserCall] = useState(false);
  const [closedUserId, setClosedUserId] = useState<any>(null);
  const [selectedField, setSelectedField] = useState('');
  const [filterType, setFilterType] = useState('Normal');
  const [isHovered, setIsHovered] = useState('');
  const [isClicked, setIsClicked] = useState(false);
  const showGoalProgress = getGoalProgressShowOrNot(groupUsers.data?.showGoalProgress);
  const { forMobile } = useResponsiveScreenSwitch();
  const classs = adminPanel();
  const [viewAnswerOpen, setViewAnswerOpen] = useState(false);
  const [taskLoading, setTasksLoading] = useState(false);
  const [tasksData, setTasksData] = useState<IAllTaskData | null>(null);
  const handleViewAnswer = (flag: boolean) => setViewAnswerOpen(flag);

  /** Search */
  const [searchValue, setSearchValue] = useState(''); // State to store the search value

  // Filtered table data based on the search value
  const filteredUsers =
    groupUsers.data?.users.filter((user) => user.name.toLowerCase().includes(searchValue.toLowerCase())) || [];

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleOpenTasks = async (userId: string) => {
    handleViewAnswer(true);
    setTasksLoading(true);
    try {
      const response = await API.getAllTasks({ grpId, userId });
      if (response.status === 200 && response.statusText === 'OK') {
        setTasksData(response.data);
        setTasksLoading(false);
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', 'Lessons failed to load'));
      setTasksLoading(false);
    }
  };
  /** Pagination */
  // const membersList = groupUsers.data;
  // const allTotalCount = groupUsers.allTotalCount;

  // const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);

  // useEffect(() => {
  //   if (page !== 0) {
  //     // Assuming refetch is a function within the groupUsers object
  //     if (groupUsers.refetch) {
  //       groupUsers.refetch();
  //     }
  //   }
  // }, [page, groupUsers]);

  // const totalPages = Math.ceil(allTotalCount / pageSize);

  // const handlePageChange = (newPage: number) => {
  //   if (newPage >= 1 && newPage <= totalPages) {
  //     setPage(newPage);
  //   }
  // };

  const handleMouseEnter = (fieldName: string) => {
    setIsHovered(fieldName);
  };
  const handleMouseLeave = (fieldName: string) => {
    setIsHovered('');
  };
  const handleUserClosedId = (userIds: any) => {
    setClosedUserId(userIds);
  };
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleClickUploadUsers = () => {
    setOpenUploadUsers(true);
  };
  const handleCloseUploadUsers = () => {
    setOpenUploadUsers(false);
    setErrorMessage('');
    setbulkFileName('');
  };

  // const handleClickBulkUsers = () => {
  //   setOpenBulkUsers(true);
  // };
  // const handleCloseBulkUsers = () => {
  //   setOpenBulkUsers(false);
  // setBulkUserLoading(false);
  // reset();
  // };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /** Export Button Popover */
  const handleClickExport = (event: any) => {
    setAnchorExport(event.currentTarget);
    setIsClicked(true);
  };

  const handleCloseExport = () => {
    setAnchorExport(null);
    setIsClicked(false);
  };

  // const handleClickDownloadPdf = (event: any) => {
  //   console.log("DownloadPdf")
  // };
  const [exportLoader, setExportLoader] = useState(false);
  const { location } = getUserDetails();
  const handleClickExportExcel = async (plan: boolean) => {
    handleCloseExport();
    toast.success('Your report will be emailed to you shortly');
    setExportLoader(true);
    try {
      const response = await API.exportUsers({ grpId, location, orgId, plan });
      if (response.status === 200 && response.statusText === 'OK') {
        // toast.success('Your report will be emailed to you shortly');
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', 'Report filed to export'));
    } finally {
      setExportLoader(false);
    }
    // let filteredArray: any = [];
    // groupUsers.data?.users.forEach((each) => {
    //   let reqObj: any = {
    //     'First name': each.firstName,
    //     'Last name': each.lastName,
    //     Email: each.email,
    //     Role: each.role.map((role) => _.capitalize(role)).join(', '),
    //     'Program score': each.goalProgress ? each.goalProgress : '',
    //     'XP points': each.xpPoints ? each.xpPoints : '',
    //     Messages: each.sentMessages,
    //     'Onboarding status': each.onboardStatus === 'live' ? 'Adopted' : _.capitalize(each.onboardStatus),
    //     'Last login': unixToHumanReadable(each.lastLogin)
    //   };
    //   if (each.company) {
    //     reqObj = {
    //       ...reqObj,
    //       'Company name': each.company?.name ? each.company.name : '',
    //       'Company location': each.company?.location ? each.company.location : ''
    //     };
    //   }
    //   filteredArray.push(reqObj);
    // });
    // groupUsers.data?.invitedUsers.forEach((each) => {
    //   let reqObj: any = {
    //     'First name': each.firstName,
    //     'Last name': each.lastName,
    //     Email: each.email,
    //     Role: each.role.map((role) => _.capitalize(role)).join(', '),
    //     'Program score': '',
    //     'XP points': '',
    //     Messages: '',
    //     'Onboarding status': '',
    //     'Last login': ''
    //   };
    //   filteredArray.push(reqObj);
    // });
    // // Create a new workbook
    // const wb = XLSX.utils.book_new();
    // // Convert JSON data to worksheet
    // const ws = XLSX.utils.json_to_sheet(filteredArray);
    // // Add worksheet to workbook
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // // Save workbook as Excel file
    // XLSX.writeFile(wb, `${groupDetails?.name}.xlsx`);
  };

  const handleMemberDevelopmentPlan = (plan: boolean) => {
    handleClickExportExcel(plan);
  };

  // const handleClickExportPlan = (event: any) => {
  //   console.log("ExportPlan")
  // };

  /**  Upload Files in Bulk................... */
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [bulkfileName, setbulkFileName] = useState<string>('');

  const handleButtonClick = () => {
    fileInputRef.current?.click();
    // if (bulkfileName) {
    //   // setbulkFileName("");
    // }
  };

  const handleBulkFileChanges = (event: ChangeEvent<HTMLInputElement>) => {
    const selectFile = event.target.files?.[0];
    if (selectFile) {
      handleFileInputChange(selectFile);
    }
  };

  const handleDragEnter: React.DragEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      // setIsHovering(true);
    } else if (event.type === 'dragleave') {
      // setIsHovering(false);
    }
  };

  const handleDrop: React.DragEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const selectFile = event.dataTransfer.files;
    if (selectFile && selectFile[0]) {
      handleFileInputChange(selectFile[0]);
    }
  };

  const handleFileInputChange = (selectFile: File) => {
    const maxFileSize = 50 * 1024; // 50kb file size

    if (selectFile && selectFile.name.endsWith('.xlsx')) {
      if (selectFile.size > maxFileSize) {
        toast.error('The max file size is 50KB');
        return;
      } else {
        setbulkFileName(selectFile.name);
      }
    } else {
      toast.error('Only xlsx files can be uploaded');
      return;
    }
  };

  const [errorMessage, setErrorMessage] = useState<string>('');
  const handlebulkSubmit = async () => {
    setBulkUserLoading(true);
    if (bulkfileName) {
      const bulkFormData = new FormData();
      bulkFormData.append('xlsx', fileInputRef.current?.files?.[0] as File); // xlsx file accessed

      try {
        const response = await API.bulkFilesUpload({
          data: bulkFormData,
          orgId,
          grpId
        });

        if (response.status === 200 && response.statusText === 'OK') {
          if (response.data.invitedUsers) {
            dispatch(fetchGroupUsers(grpId));
          }
          toast.success(_.get(response, 'data.message'));
          handleCloseUploadUsers();
          handleClose();
          setBulkUserLoading(false);
        } else if (response.status === 207 && response.statusText === 'Multi-Status') {
          toast.success(_.get(response, 'data.message'));
          handleCloseUploadUsers();
          handleClose();
          setBulkUserLoading(false);
        } else {
          toast.error('File failed to upload');
          handleCloseUploadUsers();
          handleClose();
          setBulkUserLoading(false);
        }
      } catch (error) {
        setBulkUserLoading(false);
        toast.error('File failed to upload');
      }
    } else {
      setBulkUserLoading(false);
      // toast.error("select a file first");
      setErrorMessage('*Please Choose a File to Upload.');
      return;
    }
  };

  const handleDownload = () => {
    const data = [
      ['First Name', 'Last Name', 'Email', 'Role'],
      ['oscar', 'robertson', 'oscarrobertson24@gmail.rcom', 'mentor'],
      ['Magic', 'johnson', 'johnmagic@gmail.com', 'mentee'],
      ['John', 'Smith', 'jsmith007@gmail.com', 'admin']
    ];
    const filename = 'template.xlsx';
    const sheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1');
    XLSX.writeFile(workbook, filename);
  };

  const open = Boolean(anchorEl);
  const openExport = Boolean(anchorExport);

  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    dispatch(fetchGroupUsers(grpId));
  }, []);

  useEffect(() => {
    if (grpId && orgId) {
      dispatch(fetchAllGroupsList({ orgId: orgId, groupId: grpId }));
    }
  }, []);

  interface DataItem {
    [key: string]: any;
  }

  interface Props {
    jsonData: DataItem[];
    fileName: string;
  }

  const [page, setPage] = useState(1);

  const showLoader = useLoader(groupUsers.loading, 1000);

  if (groupUsers.error) {
    return <Box>{groupUsers.errorText}</Box>;
  }

  let sortedUsers: any[];
  let filterAppliedFlag = false;
  let filterTypeValue = 'Normal';
  const clickFilterHandle = (clickedField: string) => {
    if (selectedField === clickedField) {
      if (filterType === 'Normal') {
        filterTypeValue = 'ASC';
      } else if (filterType === 'ASC') {
        filterTypeValue = 'DESC';
      } else {
        filterTypeValue = 'Normal';
      }
    } else {
      // new field selected for the first time
      filterTypeValue = 'ASC';
    }
    setFilterType(filterTypeValue);
    setSelectedField(clickedField); // after checking the filter type, assign the value
    // name filter
    if (!filterAppliedFlag) {
      filterAppliedFlag = true;
      if (filterTypeValue === 'Normal') {
        dispatch(fetchGroupUsers(grpId));
      } else {
        // *** ASC or DESC ***
        if (groupUsers.data && (groupUsers.data.users.length > 0 || groupUsers.data.invitedUsers.length > 0)) {
          // name filter
          if (clickedField === 'name') {
            if (filterTypeValue === 'ASC') {
              sortedUsers = sortedUsers.slice().sort((a, b) => a.name.localeCompare(b.name));
            } else {
              // desc
              sortedUsers = sortedUsers.slice().sort((a, b) => b.name.localeCompare(a.name));
            }
          }
          // messages count filter
          if (clickedField === 'messages') {
            if (filterTypeValue === 'ASC') {
              sortedUsers = groupUsers.data.users.slice().sort((a, b) => b.sentMessages - a.sentMessages);
            } else {
              sortedUsers = groupUsers.data.users.slice().sort((a, b) => a.sentMessages - b.sentMessages);
            }
          }
          // goal progress filter
          if (clickedField === 'goalProgress') {
            if (filterTypeValue === 'ASC') {
              sortedUsers = groupUsers.data.users.slice().sort((a, b) => (b.goalProgress ?? 0) - (a.goalProgress ?? 0));
            } else {
              sortedUsers = groupUsers.data.users.slice().sort((a, b) => (a.goalProgress ?? 0) - (b.goalProgress ?? 0));
            }
          }
          /** Program role filter */
          if (clickedField === 'role') {
            const rolePriority = {
              admin: 1,
              mentee: 2,
              mentor: 3
            };
            const getHighestRolePriority = (user: IGroupUser): number => {
              let minRolePriority = Number.MAX_SAFE_INTEGER;
              user.role.forEach((role) => {
                const priority = rolePriority[role as keyof typeof rolePriority];
                if (priority < minRolePriority) {
                  minRolePriority = priority;
                }
              });
              return minRolePriority;
            };
            if (filterTypeValue === 'ASC') {
              sortedUsers = groupUsers.data.users.slice().sort((a, b) => {
                const highestRoleA = getHighestRolePriority(a);
                const highestRoleB = getHighestRolePriority(b);
                return highestRoleA - highestRoleB;
              });
            } else {
              sortedUsers = groupUsers.data.users.slice().sort((a, b) => {
                const highestRoleA = getHighestRolePriority(b);
                const highestRoleB = getHighestRolePriority(a);
                return highestRoleA - highestRoleB;
              });
            }
          }
          // xpPoints filter
          if (clickedField === 'xpPoints') {
            if (filterTypeValue === 'ASC') {
              sortedUsers = groupUsers.data.users.slice().sort((a, b) => (b.xpPoints ?? 0) - (a.xpPoints ?? 0));
            } else {
              sortedUsers = groupUsers.data.users.slice().sort((a, b) => (a.xpPoints ?? 0) - (b.xpPoints ?? 0));
            }
          }
          // lastLogin filter
          if (clickedField === 'lastLogin') {
            if (filterTypeValue === 'ASC') {
              sortedUsers = groupUsers.data.users.slice().sort((a, b) => b.lastLogin - a.lastLogin);
            } else {
              sortedUsers = groupUsers.data.users.slice().sort((a, b) => a.lastLogin - b.lastLogin);
            }
          }
          // meetings filter
          if (clickedField === 'meetings') {
            if (filterTypeValue === 'ASC') {
              sortedUsers = groupUsers.data.users.slice().sort((a, b) => b.meetings - a.meetings);
            } else {
              sortedUsers = groupUsers.data.users.slice().sort((a, b) => a.meetings - b.meetings);
            }
          }
          // onboardingStatus filter
          if (clickedField === 'onboardingStatus') {
            if (filterTypeValue === 'ASC') {
              sortedUsers = groupUsers.data.users
                .slice()
                .sort((a, b) => a.onboardStatus.localeCompare(b.onboardStatus));
            } else {
              // desc
              sortedUsers = groupUsers.data.users
                .slice()
                .sort((a, b) => b.onboardStatus.localeCompare(a.onboardStatus));
            }
          }
          if (clickedField === 'company') {
            if (filterTypeValue === 'ASC') {
              sortedUsers = sortedUsers
                .slice()
                .sort((a, b) => (a.company?.name || '').localeCompare(b.company?.name || ''));
            } else {
              // desc
              sortedUsers = sortedUsers
                .slice()
                .sort((a, b) => (b.company?.name || '').localeCompare(a.company?.name || ''));
            }
          }
          if (clickedField === 'location') {
            if (filterTypeValue === 'ASC') {
              sortedUsers = sortedUsers
                .slice()
                .sort((a, b) => (a.company?.location || '').localeCompare(b.company?.location || ''));
            } else {
              // desc
              sortedUsers = sortedUsers
                .slice()
                .sort((a, b) => (b.company?.location || '').localeCompare(a.company?.location || ''));
            }
          }
          dispatch(groupUsersActions.updateGroupUserList(sortedUsers));
        }
      }
    }
  };

  const itemsPerPage = 25;
  const totalItems = 10 * itemsPerPage; // 10 pages * 25 items per page
  const totalPages = 10; // Total number of pages

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  if (groupUsers.data) {
    sortedUsers = groupUsers.data.users;
    return (
      <>
        {showLoader ? (
          <ProgramUsersLoader />
        ) : (
          <Box className={classs.mainBody}>
            {showProfile ? (
              <ProgramUserDetails
                actievUserId={activeUserId}
                setScrollToUserCall={setScrollToUserCall}
                setShowProfile={setShowProfile}
                grpId={grpId}
                handleUserClosedId={handleUserClosedId}
                handleOpenTasks={handleOpenTasks}
              />
            ) : (
              <Box height="100%" bgcolor="#FFFFFF" borderRadius="8px" border="1px solid #EFF0F4" overflow="auto">
                <Box px={2} display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center" columnGap="50px">
                    <Typography textAlign="left" variant="h5" py={2}>
                      Member List
                    </Typography>
                    {/* Search bar */}
                    {/* <FilterSearch
                        width="294px"
                        value={searchValue}
                        onChange={handleSearchChange}
                        userTypeDate={{ placeholderText: 'Search Mentee / Mentor' }}
                      /> */}
                  </Box>
                  <Box display="flex" gap={1} alignItems="center">
                    {/* <IconButton>
                        <FilterIcon size={34} />
                      </IconButton> */}
                    <Box>
                      <IconButton onClick={handleClick} className="p-0">
                        <PlusCircleIcon size={34} />
                      </IconButton>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                      >
                        <Box className="PopoverListStyle" onClick={handleClickOpenDialog}>
                          <ProfileIcon />
                          <Typography color="primary">Invite member</Typography>
                        </Box>
                        <Box className="PopoverListStyle" onClick={handleClickUploadUsers}>
                          <img src={bulkupload} alt="bulkupload" width="20" height="20" />
                          <Typography color="primary">Bulk upload users</Typography>
                        </Box>
                      </Popover>
                    </Box>
                    {groupUsers?.data?.users.length > 0 && (
                      <Box>
                        {/* Export btn */}
                        <ExportButton onClick={handleClickExport} isClicked={isClicked} disabled={exportLoader} />
                        <ExportPopover
                          open={openExport}
                          anchorEl={anchorExport}
                          //handleDownloadPdf={handleClickDownloadPdf}
                          handleExportExcel={handleClickExportExcel}
                          handleMemberDevelopment={handleMemberDevelopmentPlan}
                          //handleExportPlan={handleClickExportPlan}
                          onClose={handleCloseExport}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
                <Divider sx={{ opacity: 0.1, border: '.5px' }} />
                <TableContainer className="b-none">
                  <Table className="tableBody" width="100%" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Box
                            display="flex"
                            alignItems="center"
                            className="cursor-pointer"
                            onMouseEnter={() => handleMouseEnter('name')}
                            onMouseLeave={() => handleMouseLeave('name')}
                            onClick={() => clickFilterHandle('name')}
                          >
                            MEMBER
                            <ArrowUpwardIcon
                              sx={{
                                color: '#ABB5BE',
                                width: '15px',
                                height: '15px',
                                opacity:
                                  (filterType === 'ASC' && selectedField === 'name') ||
                                    (isHovered === 'name' && (filterType !== 'DESC' || selectedField !== 'name'))
                                    ? 1
                                    : 0
                              }}
                            />
                            <ArrowDownwardIcon
                              sx={{
                                color: '#ABB5BE',
                                width: '15px',
                                height: '15px',
                                opacity: filterType === 'DESC' && selectedField === 'name' ? 1 : 0
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <Box
                            display="flex"
                            alignItems="center"
                            className="cursor-pointer"
                            onMouseEnter={() => handleMouseEnter('role')}
                            onMouseLeave={() => handleMouseLeave('role')}
                            onClick={() => clickFilterHandle('role')}
                          >
                            ROLE
                            <ArrowUpwardIcon
                              sx={{
                                color: '#ABB5BE',
                                width: '15px',
                                height: '15px',
                                opacity:
                                  (filterType === 'ASC' && selectedField === 'role') ||
                                    (isHovered === 'role' && (filterType !== 'DESC' || selectedField !== 'role'))
                                    ? 1
                                    : 0
                              }}
                            />
                            <ArrowDownwardIcon
                              sx={{
                                color: '#ABB5BE',
                                width: '15px',
                                height: '15px',
                                opacity: filterType === 'DESC' && selectedField === 'role' ? 1 : 0
                              }}
                            />
                          </Box>
                        </TableCell>

                        {showCompany && (
                          <TableCell colSpan={3}>
                            <Box
                              display="flex"
                              alignItems="center"
                              className="cursor-pointer"
                              onMouseEnter={() => handleMouseEnter('company')}
                              onMouseLeave={() => handleMouseLeave('company')}
                              onClick={() => clickFilterHandle('company')}
                            >
                              COMPANY NAME
                              <ArrowUpwardIcon
                                sx={{
                                  color: '#ABB5BE',
                                  width: '15px',
                                  height: '15px',
                                  opacity:
                                    (filterType === 'ASC' && selectedField === 'company') ||
                                      (isHovered === 'company' && (filterType !== 'DESC' || selectedField !== 'company'))
                                      ? 1
                                      : 0
                                }}
                              />
                              <ArrowDownwardIcon
                                sx={{
                                  color: '#ABB5BE',
                                  width: '15px',
                                  height: '15px',
                                  opacity: filterType === 'DESC' && selectedField === 'company' ? 1 : 0
                                }}
                              />
                            </Box>
                          </TableCell>
                        )}

                        {showCompany && (
                          <TableCell colSpan={2}>
                            <Box
                              display="flex"
                              alignItems="center"
                              className="cursor-pointer"
                              onMouseEnter={() => handleMouseEnter('location')}
                              onMouseLeave={() => handleMouseLeave('location')}
                              onClick={() => clickFilterHandle('location')}
                            >
                              COMPANY LOCATION
                              <ArrowUpwardIcon
                                sx={{
                                  color: '#ABB5BE',
                                  width: '15px',
                                  height: '15px',
                                  opacity:
                                    (filterType === 'ASC' && selectedField === 'location') ||
                                      (isHovered === 'location' &&
                                        (filterType !== 'DESC' || selectedField !== 'location'))
                                      ? 1
                                      : 0
                                }}
                              />
                              <ArrowDownwardIcon
                                sx={{
                                  color: '#ABB5BE',
                                  width: '15px',
                                  height: '15px',
                                  opacity: filterType === 'DESC' && selectedField === 'location' ? 1 : 0
                                }}
                              />
                            </Box>
                          </TableCell>
                        )}

                        <TableCell colSpan={3}>
                          <Box
                            display="flex"
                            alignItems="center"
                            className="cursor-pointer"
                            onMouseEnter={() => handleMouseEnter('lastLogin')}
                            onMouseLeave={() => handleMouseLeave('lastLogin')}
                            onClick={() => clickFilterHandle('lastLogin')}
                          >
                            LAST LOGIN
                            <ArrowUpwardIcon
                              sx={{
                                color: '#ABB5BE',
                                width: '15px',
                                height: '15px',
                                opacity:
                                  (filterType === 'ASC' && selectedField === 'lastLogin') ||
                                    (isHovered === 'lastLogin' &&
                                      (filterType !== 'DESC' || selectedField !== 'lastLogin'))
                                    ? 1
                                    : 0
                              }}
                            />
                            <ArrowDownwardIcon
                              sx={{
                                color: '#ABB5BE',
                                width: '15px',
                                height: '15px',
                                opacity: filterType === 'DESC' && selectedField === 'lastLogin' ? 1 : 0
                              }}
                            />
                          </Box>
                        </TableCell>
                        {/* On Board status */}
                        <TableCell>
                          <Box
                            display="flex"
                            alignItems="center"
                            className="cursor-pointer"
                            onMouseEnter={() => handleMouseEnter('onboardingStatus')}
                            onMouseLeave={() => handleMouseLeave('onboardingStatus')}
                            onClick={() => clickFilterHandle('onboardingStatus')}
                          >
                            ONBOARDING STATUS
                            <ArrowUpwardIcon
                              sx={{
                                color: '#ABB5BE',
                                width: '15px',
                                height: '15px',
                                opacity:
                                  (filterType === 'ASC' && selectedField === 'onboardingStatus') ||
                                    (isHovered === 'onboardingStatus' &&
                                      (filterType !== 'DESC' || selectedField !== 'onboardingStatus'))
                                    ? 1
                                    : 0
                              }}
                            />
                            <ArrowDownwardIcon
                              sx={{
                                color: '#ABB5BE',
                                width: '15px',
                                height: '15px',
                                opacity: filterType === 'DESC' && selectedField === 'onboardingStatus' ? 1 : 0
                              }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            display="flex"
                            alignItems="center"
                            className="cursor-pointer"
                            onMouseEnter={() => handleMouseEnter('messages')}
                            onMouseLeave={() => handleMouseLeave('messages')}
                            onClick={() => clickFilterHandle('messages')}
                          >
                            MESSAGES
                            <ArrowUpwardIcon
                              sx={{
                                color: '#ABB5BE',
                                width: '15px',
                                height: '15px',
                                opacity:
                                  (filterType === 'ASC' && selectedField === 'messages') ||
                                    (isHovered === 'messages' && (filterType !== 'DESC' || selectedField !== 'messages'))
                                    ? 1
                                    : 0
                              }}
                            />
                            <ArrowDownwardIcon
                              sx={{
                                color: '#ABB5BE',
                                width: '15px',
                                height: '15px',
                                opacity: filterType === 'DESC' && selectedField === 'messages' ? 1 : 0
                              }}
                            />
                          </Box>
                        </TableCell>
                        {showGoalProgress && (
                          <TableCell colSpan={3}>
                            <Box
                              display="flex"
                              alignItems="center"
                              className="cursor-pointer"
                              onMouseEnter={() => handleMouseEnter('goalProgress')}
                              onMouseLeave={() => handleMouseLeave('goalProgress')}
                              onClick={() => clickFilterHandle('goalProgress')}
                            >
                              GOAL PROGRESS
                              <ArrowUpwardIcon
                                sx={{
                                  color: '#ABB5BE',
                                  width: '15px',
                                  height: '15px',
                                  opacity:
                                    (filterType === 'ASC' && selectedField === 'goalProgress') ||
                                      (isHovered === 'goalProgress' &&
                                        (filterType !== 'DESC' || selectedField !== 'goalProgress'))
                                      ? 1
                                      : 0
                                }}
                              />
                              <ArrowDownwardIcon
                                sx={{
                                  color: '#ABB5BE',
                                  width: '15px',
                                  height: '15px',
                                  opacity: filterType === 'DESC' && selectedField === 'goalProgress' ? 1 : 0
                                }}
                              />
                            </Box>
                          </TableCell>
                        )}
                        <TableCell colSpan={3}>
                          <Box
                            display="flex"
                            alignItems="center"
                            className="cursor-pointer"
                            onMouseEnter={() => handleMouseEnter('xpPoints')}
                            onMouseLeave={() => handleMouseLeave('xpPoints')}
                            onClick={() => clickFilterHandle('xpPoints')}
                          >
                            EXPERIENCE POINTS
                            <ArrowUpwardIcon
                              sx={{
                                color: '#ABB5BE',
                                width: '15px',
                                height: '15px',
                                opacity:
                                  (filterType === 'ASC' && selectedField === 'xpPoints') ||
                                    (isHovered === 'xpPoints' && (filterType !== 'DESC' || selectedField !== 'xpPoints'))
                                    ? 1
                                    : 0
                              }}
                            />
                            <ArrowDownwardIcon
                              sx={{
                                color: '#ABB5BE',
                                width: '15px',
                                height: '15px',
                                opacity: filterType === 'DESC' && selectedField === 'xpPoints' ? 1 : 0
                              }}
                            />
                          </Box>
                        </TableCell>

                        <TableCell colSpan={3}>
                          <Box
                            display="flex"
                            alignItems="center"
                            className="cursor-pointer"
                            onMouseEnter={() => handleMouseEnter('meetings')}
                            onMouseLeave={() => handleMouseLeave('meetings')}
                            onClick={() => clickFilterHandle('meetings')}
                          >
                            MEETINGS
                            <ArrowUpwardIcon
                              sx={{
                                color: '#ABB5BE',
                                width: '15px',
                                height: '15px',
                                opacity: filterType === 'ASC' && selectedField === 'meetings' ? 1 : 0
                              }}
                            />
                            <ArrowDownwardIcon
                              sx={{
                                color: '#ABB5BE',
                                width: '15px',
                                height: '15px',
                                opacity: filterType === 'DESC' && selectedField === 'meetings' ? 1 : 0
                              }}
                            />
                          </Box>
                        </TableCell>

                        {/* <TableCell>Activity</TableCell> */}
                        {/* <TableCell>CALLS</TableCell> */}
                        {/* <TableCell>CONTENT VIEWED</TableCell> */}
                        <TableCell colSpan={3}>ACTION</TableCell>
                      </TableRow>
                    </TableHead>
                    {/* <TableBody>
                        {groupUsers.data &&
                        (groupUsers.data.users.length > 0 || groupUsers.data.invitedUsers.length > 0) ? (
                          sortedUsers.map((each: IGroupUser) => {
                            return (
                              <ProgramUserTableRow
                                key={each.userId} // unique userid as a key
                                data={each}
                                setShowProfile={setShowProfile}
                                setActiveUserId={setActiveUserId}
                                scrollToUserCall={scrollToUserCall}
                                setScrollToUserCall={setScrollToUserCall}
                                closedUserId={closedUserId}
                                showGoalProgress = {showGoalProgress}
                                // anchorAction={anchorAction}
                                // setAnchorAction={setAnchorAction}
                              />
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={12}>
                              <Typography textAlign="center">No members have been invited to this program yet</Typography>
                            </TableCell>
                          </TableRow>
                        )}
                        {groupUsers.data.invitedUsers &&
                          groupUsers.data.invitedUsers.length > 0 &&
                          groupUsers.data.invitedUsers.map((each) => (
                            <React.Fragment key={each.userId}>
                              <ProgramInvitedUsers data={each} showGoalProgress ={showGoalProgress} />
                            </React.Fragment>
                          ))}
                      </TableBody> */}
                    <TableBody>
                      {groupUsers.data &&
                        (groupUsers.data.users.length > 0 || groupUsers.data.invitedUsers.length > 0) ? (
                        filteredUsers.map((each: IGroupUser) => {
                          return (
                            <ProgramUserTableRow
                              key={each.userId} // unique userid as a key
                              data={each}
                              setShowProfile={setShowProfile}
                              setActiveUserId={setActiveUserId}
                              scrollToUserCall={scrollToUserCall}
                              setScrollToUserCall={setScrollToUserCall}
                              closedUserId={closedUserId}
                              showGoalProgress={showGoalProgress}
                              handleOpenTasks={handleOpenTasks}
                              showCompany={showCompany}
                            />
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <Typography textAlign="center">No members have been invited to this program yet</Typography>
                          </TableCell>
                        </TableRow>
                      )}
                      {groupUsers.data.invitedUsers &&
                        groupUsers.data.invitedUsers.length > 0 &&
                        groupUsers.data.invitedUsers.map((each) => (
                          <React.Fragment key={each.userId}>
                            <ProgramInvitedUsers
                              data={each}
                              showCompany={showCompany}
                              showGoalProgress={showGoalProgress}
                            />
                          </React.Fragment>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* Pagination */}
                {/* <Box display={'flex'} justifyContent={'center'} className='my-20'>
                    <MyPagination
                      counts={[{ count: totalPages }]} 
                      currentPage={page} 
                      onPageChange={handlePageChange} 
                      allTotalObjects={totalItems} 
                      pageSize={itemsPerPage} 
                    />
                  </Box> */}
              </Box>
            )}
          </Box>
        )}

        {/* Invite user dialog */}
        <InviteUserDialog handleClose={handleClose} openDialog={openDialog} setOpenDialog={setOpenDialog} type="Add" />

        {/*  Bulk upload members */}
        <Dialog open={openUploadUsers} onClose={handleCloseUploadUsers} scroll="body" fullWidth>
          <CommonModalHeader title="Bulk upload members" handleCloseModal={handleCloseUploadUsers} />
          <DialogContent>
            <Typography>
              In order to bulk upload members, please download the template file to proceed. <br /> First name, last
              name, email address, and role are required.
            </Typography>
            <Box display="flex" alignItems="center" gap="8px" my={2}>
              <img
                src={downloadblue}
                alt="downloadblueimg"
                width="21"
                height="21"
                className="cursor-pointer"
                onClick={handleDownload}
              />
              <Typography className="cursor-pointer" variant="subtitle2" color="#0071A9" onClick={handleDownload}>
                Download bulk upload template
              </Typography>
            </Box>
            <Box
              p={2.5}
              borderRadius="8px"
              bgcolor="#FFFFFF"
              border="1px dashed #DEDFDF"
              className="cursor-pointer"
              onClick={handleButtonClick}
            >
              <Box textAlign="center" onDragEnter={handleDragEnter} onDragOver={handleDragEnter} onDrop={handleDrop}>
                <IconButton>
                  <img src={upload} alt="upload" width="25" height="25" />
                </IconButton>
                <input
                  ref={fileInputRef}
                  type="file"
                  className="d-none"
                  placeholder="file"
                  accept=".xlsx"
                  onChange={handleBulkFileChanges}
                />
                <Box>
                  <p className="fs-12 secondary-light fw-600">
                    {' '}
                    <span color="color-blue fs-14">Click to upload </span>
                    or drag <br /> Excel file only (max. 50 KB) <br />
                  </p>
                  {bulkfileName ? (
                    <Typography mt={1} variant="h5" style={{ color: 'green' }}>
                      {bulkfileName}
                    </Typography>
                  ) : (
                    <FormHelperText error sx={{ textAlign: 'center' }}>
                      {errorMessage}
                    </FormHelperText>
                  )}
                </Box>
              </Box>
            </Box>
          </DialogContent>

          <DialogActions>
            <LoadingButton
              color="primary"
              variant="contained"
              fullWidth
              onClick={handlebulkSubmit}
              loading={bulkUserLoading}
            >
              Add
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <LevelQuestionAnswers
          open={viewAnswerOpen}
          setOpen={setViewAnswerOpen}
          taskLoading={taskLoading}
          tasksData={tasksData}
        />

        {/* After upload the bulk member code */}
        {/* <Dialog open={openBulkUsers} onClose={handleCloseBulkUsers} fullWidth>
          <CommonModalHeader
            handleCloseModal={handleCloseBulkUsers}
            title={'Bulk upload users'}
          />
          <DialogContent>
            <Box display='flex' gap='8px'>
              <Box>
                <img src={excelimg} alt="excelimg" width='24' height='24' />
              </Box>
              <Box width='calc(100% - 32px)'>
                <Box>
                  <Box display='flex' alignItems='center' gap='8px'>
                    <Typography variant='h6' maxWidth='calc(100% - 55px)' noWrap>Network Groups users.xlsx</Typography>
                    <Typography color='error' fontSize='10px' fontWeight='600'>Remove</Typography>
                  </Box>
                  <Typography fontSize='10px' fontWeight='600' mt={.5}>* Excel file only (max. 10 KB)</Typography>
                </Box>
                <Box mt={1}>
                  <LinearProgressWithLabel value={89} />
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant='contained'
              fullWidth
              onClick={handleClickBulkUsers}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog> */}

        {/* Filter */}
        {/* <UserFilter
          loading={showLoader}
          openFilter={openFilter}
          handleopenFilter={handleopenFilter}
        /> */}
      </>
    );
  }
  return null;
};

export default ProgramUsers;
