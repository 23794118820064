/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import '../../../assets/style/sass/community.scss';
import {
  Box,
  Grid,
  Button,
  Dialog,
  Divider,
  Accordion,
  Typography,
  IconButton,
  DialogActions,
  DialogContent,
  AccordionDetails,
  AccordionSummary
} from '@mui/material';
import _ from 'lodash';
import {
  rolesArray,
  topicArray,
  industryArray,
  IsAgileExecutive,
  formatNumberWithCommas,
  sortedCountries as countries
} from '../components/common';
import { API } from '../../../api';
// import { toast } from 'react-toastify';
import ROLES from '../../../utils/roles';
import { useNavigate } from 'react-router';
import UserCard from './community/UserCard';
import { useAuth0 } from '@auth0/auth0-react';
import { FilterIcon } from '../../../utils/Icons';
import { useLoader } from '../components/useLoader';
import { getUserDetails } from '../../../utils/orgName';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterLoader from '../components/filter/FilterLoader';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { AGILE_ALLOWED_CATEGORY_COMMUNITY } from '../../../utils/constants';
// import UserProfileDetailsPopup from '../components/UserProfileDetailsPopup';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';
import { FilterCheckField, FilterSearch } from '../components/filter/FilterProperty';
import { fetchCommunityMember } from '../../../redux/slices/communityMembers/communityMembersSlice';
import { communityFilterAction } from '../../../redux/slices/community-filters/communityFilterSlice';
import { camelCaseToCapitalIndustries, capitalToCamelCaseIndustries } from '../../../utils/industriesMapping';

interface FilterVariables {
  applyFilter: boolean;
}

interface IInitialState {
  loading: boolean;
  data: any;
  error: boolean;
  errorText: string;
}

interface CommunityMember {
  bio: Record<string, any>;
  digital_intro: Record<string, any>;
  displayName: string;
  id: {
    category: string;
    coId: string;
    communicationId: string;
    country: string;
    disability: string[];
    dob: string;
    email: string;
    ethnicity: string;
    firstGenStudent: string;
    firstName: string;
    ftue: boolean;
    gender: string;
    headshot: string;
    hobbies: string[];
    isActive: boolean;
    lastName: string;
    name: string;
    phoneNumber: string;
    phonePrefix: string;
    social: {
      facebook: string;
      instagram: string;
      linkedIn: string;
      twitter: string;
      youtube: string;
      // Add any other social properties as needed
    };
    timezone: string;
  };
  userId: string;
  // Add any other properties as needed
}

const Community = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const { orgId, location } = getUserDetails();
  const [loading, setLoading] = useState<boolean>(true);
  const [filterVariables, setFilterVariables] = useState<FilterVariables>({
    applyFilter: false
  });
  let roles: any[] = rolesArray();
  const orgDetails = useAppSelector((state) => state.orgDetails.data?.userTypes);
  let customerCategory: any[] = orgDetails || [];
  const { applyFilter } = filterVariables;
  const [openFilter, setOpenFilter] = React.useState(false);
  const [allCommunityMembersList, setAllCommunityMembersList] = useState<any[]>([]);
  const [selectedRole, setSelectedRole] = useState<string[]>([]);
  const [selectedUserType, setSelectedUserType] = useState<string[]>([]);
  const [selectedIndustry, setselectedIndustry] = useState<string[]>([]);
  const [selectedSkillType, setSelectedSkillType] = useState<string[]>([]);
  const [selectedHobbies, setselectedHobbies] = useState<string[]>([]);
  const [selectedBsIndustries, setSelectedBsIndustries] = useState<string[]>([]);
  const [hobbiesList, setHobbiesList] = useState<string[]>([]);
  const [industryList, setIndustryList] = useState<string[]>([]);
  const [bsIndustriesList, setBsIndustriesList] = useState<string[]>([]);
  const [roleTypeList, setRoleTypeList] = useState<string[]>([]);
  const [skillTypeList, setSkillTypeList] = useState<string[]>([]);
  const uniqueSkillTypelist = [...new Set(skillTypeList)];
  const uniqueBsIndustrieslist = [...new Set(bsIndustriesList)];
  const [countryList, setCountryList] = useState(countries);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);

  const handleCountryChange = (country: string) => {
    setSelectedCountries((prevCountry) =>
      prevCountry?.includes(country) ? prevCountry?.filter((c) => c !== country) : [...(prevCountry || []), country]
    );
  };

  const handleSearchCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCountry(event.target.value);
    const { value } = event.target;
    const filteredCountries = countries.filter((country) => country.label.toLowerCase().includes(value.toLowerCase()));
    setCountryList(filteredCountries);
  };

  // Skill
  const topics = topicArray();
  const [searchTopics, setSearchTopics] = useState<string>('');
  const [searchCounrty, setSearchCountry] = useState<string>('');
  const topicsList = uniqueSkillTypelist.map((tag) => {
    const index = topics.findIndex((each) => each.name.toLowerCase() === tag.toLowerCase());
    if (index !== -1) {
      return { label: topics[index].label, name: topics[index].name };
    }
    return { label: tag, name: tag };
  });

  // basic settings industries
  const industries = industryArray();
  const filteredBsIndustriesList = uniqueBsIndustrieslist.map((tag) => {
    const index = industries.findIndex((each) => each.name.toLowerCase() === tag.toLowerCase());
    if (index !== -1) {
      return { label: industries[index].label, name: industries[index].name };
    }
    return { label: tag, name: tag };
  });
  const filteredTopicsList = topicsList.filter((tags) => tags.name.toLowerCase().includes(searchTopics.toLowerCase()));
  const communityMembers = useAppSelector((state) => state.getCommunityMembers);
  const communityFilters = useAppSelector((state) => state.communityFilters.data);
  const categoryUserType = useAppSelector((state) => state?.orgDetails.data?.orgType) || [];
  const showLoader = useLoader(loading && communityMembers?.loading, 1000);
  // const [searchSkillTerm, setSearchSkillTerm] = useState<string>('');

  /** For open member Profile Details popup */
  // const [openUserProfileDetails, setOpenUserProfileDetails] = React.useState(false);
  // const [selectedMember, setSelectedMember] = useState(null);
  // const handleopenUserProfileDetails = (flag: boolean) => setOpenUserProfileDetails(flag);

  /** Active inactive */
  const isActive = true;
  const getLinkStyles = (isActive?: any) => ({
    color: isActive ? '#df6438' : 'primary',
    fontWeight: isActive ? '600' : 'normal',
    borderBottom: isActive ? '3px solid #df6438' : 'none',
    pb: '17px',
    px: 2
  });

  const navigate = useNavigate();
  const handleSearchSkills = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTopics(event.target.value);
  };

  // const filteredRoleTypes = skillTypeList.filter((roleType) =>
  //   roleType.toLowerCase().includes(searchTopics.toLowerCase())
  // );

  // const uniqueRoleTypes = [...new Set(filteredRoleTypes)];

  const handleRoleChange = (role: string) => {
    // console.log(`${role} clicked`);
    // const handleIndustryChange = (industry: string) => {
    setSelectedRole((prevRole) =>
      prevRole?.includes(role) ? prevRole?.filter((rol) => rol !== role) : [...(prevRole || []), role]
    );
    // };
  };

  // const topics = topicArray();
  const handleTagChange = (type: string) => {
    const index = topics.findIndex((each) => each.label === type);
    const name = index !== -1 ? topics[index].name : '';
    setSelectedSkillType((prevTypes) =>
      prevTypes.includes(name) ? prevTypes.filter((t) => t !== name) : [...prevTypes, name]
    );
  };

  const handleBsIndustriesChange = (type: string) => {
    const index = industries.findIndex((each) => each.label === type);
    const name = index !== -1 ? industries[index].name : '';
    setSelectedBsIndustries((prevTypes) =>
      prevTypes.includes(name) ? prevTypes.filter((t) => t !== name) : [...prevTypes, name]
    );
  };

  useEffect(() => {
    Promise.all([
      fetchTagList('Hobbies and Interests'),
      fetchTagList('industry'),
      fetchSkillsTopic(),
      fetchIndustries()
    ]).then(([tagList1, tagList2, tagList3, tagList4]) => {
      setHobbiesList(tagList1);
      setIndustryList(camelCaseToCapitalIndustries(tagList4));
      setSkillTypeList(tagList3);
      setBsIndustriesList(tagList4);
    });
    dispatch(
      fetchCommunityMember({
        orgId,
        location,
        role: [],
        category: [],
        industry: [],
        hobbies: [],
        roleType: []
      })
    );
  }, []);

  const isAgileExec = IsAgileExecutive();

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    fetchCommunityMembers(
      selectedRole,
      selectedUserType,
      selectedHobbies,
      selectedIndustry,
      selectedSkillType,
      selectedBsIndustries,
      selectedCountries
    );
    dispatch(
      communityFilterAction.updateCommunityFilter({
        selectedRole,
        selectedUserType,
        selectedIndustry,
        selectedSkillType,
        selectedHobbies,
        selectedBsIndustries,
        selectedCountries
      })
    );
  }, [applyFilter]);

  useEffect(() => {
    setSelectedRole(communityFilters?.selectedRole || []);
    setSelectedUserType(communityFilters?.selectedUserType || []);
    setselectedIndustry(communityFilters?.selectedIndustry || []);
    setSelectedSkillType(communityFilters?.selectedSkillType || []);
    setselectedHobbies(communityFilters?.selectedHobbies || []);
    setSelectedBsIndustries(communityFilters?.selectedBsIndustries || []);
  }, [communityFilters]);

  useEffect(() => {
    // handleReset();
    setAllCommunityMembersList(
      communityMembers?.data?.filter((each: any) => {
        if ('isPlatformAdmin' in each) {
          return false; // Exclude platform admins
        }

        if (isAgileExec && each.category && !AGILE_ALLOWED_CATEGORY_COMMUNITY.includes(each.category)) {
          return false; // Exclude members not in the allowed Agile categories
        }

        return true; // Include members that pass the conditions
      }) || []
    );
  }, [communityMembers]);

  const handleOnSelectMember = (mem: any) => {
    /** For big page profile */
    navigate('/app/communitymember', {
      state: {
        mem,
        communityMembers,
        allCommunityMembersList,
        filters: {
          selectedRole,
          selectedUserType,
          selectedIndustry,
          selectedSkillType,
          selectedHobbies,
          selectedBsIndustries
        },
        fromCommunityFirstPage: true
      }
    });

    /** For Popup */
    // setSelectedMember(mem)
    // setOpenUserProfileDetails(true);
  };

  const handleClickOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setSearchTopics('');
    setOpenFilter(false);
    setSearchCountry('');
    setCountryList(countries);
  };

  const handleReset = () => {
    setSelectedRole([]);
    setSelectedUserType([]);
    setselectedHobbies([]);
    setselectedIndustry([]);
    setSelectedSkillType([]);
    setSelectedBsIndustries([]);
    setSelectedCountries([]);
    dispatch(
      communityFilterAction.updateCommunityFilter({
        selectedRole: [],
        selectedUserType: [],
        selectedIndustry: [],
        selectedSkillType: [],
        selectedHobbies: [],
        selectedBsIndustries: [],
        selectedCountries: []
      })
    );
    dispatch(fetchCommunityMember({ orgId, location }));
    setSearchCountry('');
    setOpenFilter(false);
    setCountryList(countries);
  };

  useEffect(() => {
    // This function will be called when the component unmounts
    return () => {
      handleReset();
    };
  }, []);

  const handleApply = () => {
    setFilterVariables({
      ...filterVariables,
      applyFilter: !applyFilter
    });
    // console.log(selectedBsIndustries, 'selected bs industries');
    dispatch(
      communityFilterAction.updateCommunityFilter({
        selectedRole: selectedRole,
        selectedUserType: selectedUserType,
        selectedIndustry: selectedIndustry,
        selectedSkillType: selectedSkillType,
        selectedHobbies: selectedHobbies,
        selectedBsIndustries: selectedBsIndustries,
        selectedCountries
      })
    );
    handleCloseFilter();
  };

  const handleUserTypeChnage = (userType: string) => {
    setSelectedUserType((prevUserType) =>
      prevUserType?.includes(userType)
        ? prevUserType?.filter((type) => type !== userType)
        : [...(prevUserType || []), userType]
    );
  };

  const handleIndustryChange = (industry: string) => {
    setselectedIndustry((prevInd) =>
      prevInd?.includes(industry) ? prevInd?.filter((ind) => ind !== industry) : [...(prevInd || []), industry]
    );
  };

  const handleHobbiesChange = (hobbies: string) => {
    setselectedHobbies((prevHobbies) =>
      prevHobbies?.includes(hobbies) ? prevHobbies?.filter((hob) => hob !== hobbies) : [...(prevHobbies || []), hobbies]
    );
  };

  // const handleSkillTypeChange = (roleType: string) => {
  //   setSelectedSkillType((prevRoleType) =>
  //     prevRoleType?.includes(roleType)
  //       ? prevRoleType?.filter((role) => role !== roleType)
  //       : [...(prevRoleType || []), roleType]
  //   );
  // };

  // const handleSkillTypeChange = (type: string) => {
  //   const index = topics.findIndex((each) => each.label === type);
  //   // const name = index ? topics[index].name : '';
  //   const name = index !== -1 ? topics[index].name : '';

  //   setSelectedSkillType((prevTypes) =>
  //     prevTypes.includes(name) ? prevTypes.filter((t) => t !== name) : [...prevTypes, name]
  //   );
  // };

  /** Get bio */
  const getBio = (mem: any) => {
    const category = _.get(mem, 'category', '');
    const { major = '', university = '' } = _.get(mem, 'bio.education', {});
    const { role = '', companyName = '' } = _.get(mem, 'bio.workHistory', {});

    return category === 'Student'
      ? `${major}${major && university ? ', ' : ''}${university}`
      : `${role}${role && companyName ? ', ' : ''}${companyName}`;
  };

  const fetchCommunityMembers = async (
    roleSelected: string[],
    category: string[],
    hobbiesAndInterest: string[],
    industry: string[],
    skillType: string[],
    bsIndustries: string[],
    countries: string[]
  ) => {
    setLoading(true);

    const filteredResponse =
      communityMembers?.data &&
      (roleSelected?.length ||
        hobbiesAndInterest?.length ||
        industry?.length ||
        skillType?.length ||
        bsIndustries?.length ||
        countries?.length ||
        category?.length)
        ? communityMembers?.data.filter((member: any) => {
            // const roleMatch = member?.userType?.some((each: any) => roleSelected?.map(s => s.toLowerCase().includes(each.toLowerCase())))
            const roleMatch =
              roleSelected?.length > 0
                ? member?.userType?.length === 0 && roleSelected.includes('Admin')
                  ? true
                  : member?.userType?.find((each: any) =>
                      roleSelected?.includes(each.charAt(0).toUpperCase() + each.slice(1))
                    )
                : true;

            const hobbiesMatch =
              hobbiesAndInterest?.length > 0
                ? member?.hobbies?.some((hobby: any) =>
                    hobbiesAndInterest?.map((s) => s.toLowerCase()).includes(hobby.toLowerCase())
                  )
                : true;
            // const industryMatch =
            //   industry?.length > 0
            //     ? member?.industries?.some((ind: any) =>
            //         industry?.map((s) => s.toLowerCase()).includes(ind.toLowerCase())
            //       )
            //     : true;
            const convertedIndustry = capitalToCamelCaseIndustries(industry);
            const industryMatch =
              industry?.length > 0
                ? member?.savedIndustries?.some((ind: any) =>
                    convertedIndustry?.map((s) => s.toLowerCase()).includes(ind.toLowerCase())
                  )
                : true;
            const skillsMatched =
              skillType?.length > 0
                ? member?.savedTopics?.some((skill: any) =>
                    skillType?.map((s) => s.toLowerCase()).includes(skill.toLowerCase())
                  )
                : true;
            const bsIndustriesMatched =
              bsIndustries?.length > 0
                ? member?.savedIndustries?.some((bsInd: any) =>
                    bsIndustries?.map((s) => s.toLowerCase()).includes(bsInd.toLowerCase())
                  )
                : true;
            const categoryMatch = category?.length > 0 ? category?.includes(member?.category || '') : true;

            const countryMatch = countries?.length > 0 ? countries?.includes(member?.company?.location || '') : true;

            return (
              roleMatch &&
              hobbiesMatch &&
              industryMatch &&
              skillsMatched &&
              bsIndustriesMatched &&
              countryMatch &&
              categoryMatch
            );
            // return roleMatch
          })
        : communityMembers?.data;

    // if (response?.status === 200 && response?.statusText === 'OK') {
    //   if (Array.isArray(response?.data.memberResponse)) {
    //     setAllCommunityMembersList(
    //       response?.data?.memberResponse?.filter((each: any) => {
    //         if ('isPlatformAdmin' in each) {
    //           return false; // Exclude platform admins
    //         }

    //         if (isAgileExec && each.category && !AGILE_ALLOWED_CATEGORY_COMMUNITY.includes(each.category)) {
    //           return false; // Exclude members not in the allowed Agile categories
    //         }

    //         return true; // Include members that pass the conditions
    //       }) || []
    //     );

    setAllCommunityMembersList(
      filteredResponse?.filter((each: any) => {
        if ('isPlatformAdmin' in each) {
          return false; // Exclude platform admins
        }

        if (isAgileExec && each.category && !AGILE_ALLOWED_CATEGORY_COMMUNITY.includes(each.category)) {
          return false; // Exclude members not in the allowed Agile categories
        }

        return true; // Include members that pass the conditions
      }) || []
    );

    // } else {
    //   setAllCommunityMembersList([]);
    // }
    setLoading(false);
    // }
    // } catch (error) {
    //   setLoading(false);
    //   toast.error('Failed while loading community list');
    // }}
  };
  const [showCompany, setShowCompany] = useState(false);
  const role = localStorage.getItem('role') || '';
  const isPlatformAdmin = role === ROLES.platAdmin;
  const isOrgAdmin = role === ROLES.orgAdmin;

  useEffect(() => {
    if (communityMembers?.data) {
      const isCompanyExist = communityMembers.data.some((each) =>
        each?.company && ![ROLES.orgAdmin, ROLES.platAdmin].includes(each.roleType) ? true : false
      );
      if (isCompanyExist) {
        setShowCompany(true);
      } else {
        setShowCompany(false);
      }
    }
  }, [communityMembers]);

  /** Fetch tag list */
  const fetchTagList = async (type: string) => {
    try {
      const responseFYE = await API.getTagsListnew(orgId, location, type, '', true);
      if (responseFYE.status === 200 && responseFYE.statusText === 'OK') {
        // console.log(responseFYE?.data?.tagListResponse, 'console log');
        return responseFYE?.data?.tagListResponse?.map((each: any) => each.value) || [];
      } else {
        return [];
      }
    } catch {
      return [];
    }
  };

  const fetchIndustries = async () => {
    try {
      const response = await API.getAllIndustriesForUser(location);
      if (response.status === 200 && response.statusText === 'OK') {
        return (
          // response?.data?.industries?.sort()?.map((topic: any) => topic?.charAt(0)?.toUpperCase() + topic?.slice(1)) ||
          // []
          response?.data?.industries?.sort() || []
        );
      } else {
        return [];
      }
    } catch {
      return [];
    }
  };

  const fetchSkillsTopic = async () => {
    try {
      const response = await API.getAllTopicsForUser(location);
      if (response.status === 200 && response.statusText === 'OK') {
        return (
          response?.data?.topics?.sort()?.map((topic: any) => topic?.charAt(0)?.toUpperCase() + topic?.slice(1)) || []
        );
      }
    } catch {
      return [];
    }
  };

  if (communityMembers?.error) {
    return <Box>{communityMembers?.errorText}</Box>;
  }

  return (
    <>
      <Box className="bodyBox">
        <Box className="CardBox" minHeight="100%">
          <Box display={{ sm: 'flex' }} alignItems="center" justifyContent="space-between" pb={0} px={2}>
            <Box display="flex" gap="10px" py={2}>
              <Typography variant="h5">Network</Typography>
              <Typography>
                {_.isArray(allCommunityMembersList) &&
                  `(${formatNumberWithCommas(allCommunityMembersList?.length)} members)`}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" justifyContent={{ xs: 'space-between' }} gap={2}>
              {/* <Box display="flex" columnGap="20px" pt={{ sm: 2 }}>
                <Typography className="cursor-pointer" {...getLinkStyles(isActive)}>
                  All Members
                </Typography>
                <Typography sx={{cursor: 'pointer'}} {...getLinkStyles(!isActive)}>Favorites</Typography>
              </Box> */}
              <IconButton onClick={handleClickOpenFilter}>
                <FilterIcon size={34} />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <Box p={2}>
            {showLoader || (applyFilter && loading && communityMembers?.loading) ? (
              <Grid container spacing={2}>
                {[...Array(18).keys()].map((idx) => (
                  <Grid item key={idx} lg={2} md={3} sm={4} xs={6}>
                    <UserCard loading={true} />
                  </Grid>
                ))}
              </Grid>
            ) : _.isArray(allCommunityMembersList) && !_.isEmpty(allCommunityMembersList) ? (
              <Grid container spacing={2}>
                {_.isArray(allCommunityMembersList) &&
                  !_.isEmpty(allCommunityMembersList) &&
                  [...allCommunityMembersList]
                    ?.sort((a: any, b: any) =>
                      _.get(a, 'displayName', ' ')
                        .trim()
                        .toLowerCase()
                        .localeCompare(_.get(b, 'displayName', ' ').trim().toLowerCase())
                    )
                    // ?.slice(0, 6) // Display only the first 6 members
                    ?.map((mem: any) => {
                      /** Role type */
                      const cat: any = mem?.category;
                      /** Show only member from selected category for agile */
                      if (isAgileExec && cat && !AGILE_ALLOWED_CATEGORY_COMMUNITY.includes(cat)) {
                        return null;
                      }
                      return (
                        <Grid item lg={2} md={3} sm={4} xs={12} key={mem.id}>
                          <UserCard
                            mem={mem}
                            category={cat}
                            cardWidth="100%"
                            cardHeight="100%"
                            communityMembers={communityMembers}
                            allCommunityMembersList={allCommunityMembersList}
                            handleOnSelectMember={handleOnSelectMember}
                            displayName={_.get(mem, 'displayName', '')}
                            bio={getBio(mem)}
                            // loading={true}
                            imageUrl={
                              mem?.headshot
                                ? mem?.headshot
                                : `https://ui-avatars.com/api/?name=${mem?.displayName?.split(' ')[0]}`
                            }
                          />
                        </Grid>
                      );
                    })}
              </Grid>
            ) : (
              !communityMembers?.loading &&
              !loading && (
                <Box py={2} height="100%">
                  <Typography textAlign="center">No members found</Typography>
                </Box>
              )
            )}
          </Box>
        </Box>
      </Box>

      {/* Dialog */}
      <Dialog open={openFilter} fullWidth>
        {/* Dialog Header */}
        <CommonModalHeader title="Filter" handleCloseModal={handleCloseFilter} />
        <DialogContent>
          {showLoader ? (
            <FilterLoader />
          ) : (
            <>
              {/* Role */}
              <>
                {/* <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="Role-content"
                      id="Role-header"
                      className="px-0 filterAccordionSummary"
                    >
                      <Typography variant="h5">Role</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="px-0 pb-0">
                      {roles.map((role, index) => (
                        <FilterCheckField
                          key={index}
                          label={role}
                          onChange={handleRoleChange}
                          checked={selectedRole?.includes(role)}
                        />
                      ))}
                    </AccordionDetails>
                  </Accordion> */}
                {/* <Divider /> */}
              </>
              {/* Skills */}
              {skillTypeList?.length > 0 && (
                <>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="Skills-content"
                      id="Skills-header"
                      className="px-0 filterAccordionSummary"
                    >
                      <Typography variant="h5">Expertise</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="px-0 pb-0">
                      {/* Search */}
                      <Box pb={1}>
                        <FilterSearch
                          userTypeDate={{ placeholderText: 'Search Skills' }}
                          value={searchTopics}
                          onChange={handleSearchSkills}
                        />
                      </Box>
                      {/* Skills opction */}
                      <Box>
                        {filteredTopicsList.length > 0 ? (
                          filteredTopicsList
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((tags, index) => (
                              <FilterCheckField
                                key={index}
                                label={tags.label}
                                onChange={handleTagChange}
                                checked={selectedSkillType.includes(tags.name)}
                              />
                            ))
                        ) : (
                          <Typography textAlign="center" pt={1}>
                            No data found
                          </Typography>
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </>
              )}
              {/* Industry */}
              {industryList?.length > 0 && (
                <>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="Industry-content"
                      id="Industry-header"
                      className="px-0 filterAccordionSummary"
                    >
                      <Typography variant="h5">Industries</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="px-0 pb-0">
                      {industryList
                        ?.slice()
                        ?.sort()
                        ?.map((industry, index) => {
                          return (
                            <FilterCheckField
                              key={index}
                              label={industry}
                              onChange={handleIndustryChange}
                              checked={selectedIndustry?.includes(industry)}
                            />
                          );
                        })}
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </>
              )}
              {/* Member Type */}
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="Customer-content"
                  id="Customer-header"
                  className="px-0 filterAccordionSummary"
                >
                  <Typography variant="h5">Member Type</Typography>
                </AccordionSummary>
                <AccordionDetails className="px-0 pb-0">
                  {customerCategory.map((customerCategory, index) => (
                    <FilterCheckField
                      key={index}
                      label={customerCategory}
                      onChange={handleUserTypeChnage}
                      checked={selectedUserType?.includes(customerCategory)}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
              <Divider />
              {/* Hobbies & Interests */}
              {(showCompany || isPlatformAdmin || isOrgAdmin) && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="Skills-content"
                    id="Skills-header"
                    className="px-0 filterAccordionSummary"
                  >
                    <Typography variant="h5">Company Location</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="px-0 pb-0">
                    {/* Search */}
                    <Box pb={1}>
                      <FilterSearch
                        userTypeDate={{ placeholderText: 'Search Company Location' }}
                        value={searchCounrty}
                        onChange={handleSearchCountry}
                      />
                    </Box>
                    {/* Skills opction */}
                    <Box>
                      {countryList.map((tags, index) => (
                        <FilterCheckField
                          key={index}
                          label={tags.label}
                          onChange={handleCountryChange}
                          checked={selectedCountries.includes(tags.label)}
                        />
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )}
              {/* bs industries */}
              {/* {bsIndustriesList?.length > 0 && (
                <>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="Industries-content"
                      id="Industries-header"
                      className="px-0 filterAccordionSummary"
                    >
                      <Typography variant="h5">Industries</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="px-0 pb-0">
                      <Box>
                        {filteredBsIndustriesList.length > 0 ? (
                          filteredBsIndustriesList
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((bsIndustries, index) => (
                              <FilterCheckField
                                key={index}
                                label={bsIndustries.label}
                                onChange={handleBsIndustriesChange}
                                checked={selectedBsIndustries.includes(bsIndustries.name)}
                              />
                            ))
                        ) : (
                          <Typography textAlign="center" pt={1}>
                            No data found
                          </Typography>
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </>
              )} */}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" fullWidth onClick={handleReset}>
            Reset
          </Button>
          <Button variant="contained" fullWidth onClick={handleApply}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      {/* member profile details  */}
      {/* <UserProfileDetailsPopup
        loading={false} // loader
        openUserProfileDetails={openUserProfileDetails}
        handleopenUserProfileDetails={handleopenUserProfileDetails}
        userData={selectedMember}
      /> */}
    </>
  );
};

export default Community;
