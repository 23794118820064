import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { Chat1Icon, ExploreIcon, CommunityIcon, TasksListIcon } from '../../../../utils/Icons';
import { appColors } from '../../../../utils/theme';
import MySkeleton from '../../../../components/Skeleton';
import { CardStyle } from '../../style-components/CardStyling';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
import _ from 'lodash';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { checkOrfium } from '../../../../utils/orfiumFunctions';
import { useAppSelector } from '../../../../redux/hooks';

interface gettingStartedProps {
  loading: boolean;
  handleClickGettingStartedModal: any;
  setSelectedStep: any;
  handelOnClickPreviewArticleName:any;
  helpfulTips:any;
}

const cardContent = [
  {
    icon: <ExploreIcon />,
    text: 'Familiarize yourself with the Dosen app'
  },
  {
    icon: <Chat1Icon />,
    text: 'Introduce yourself to your mentor'
  },
  {
    icon: <CommunityIcon />,
    text: 'Get to know your peers'
  },
  {
    icon: <TasksListIcon />,
    text: 'Start your Level 1 lessons'
  }
];

const GettingStartedCard: React.FC<gettingStartedProps> = ({
  loading,
  handleClickGettingStartedModal,
  handelOnClickPreviewArticleName,
  helpfulTips,
  setSelectedStep
}) => {
  const classes = CardStyle();
  const { forMobile } = useResponsiveScreenSwitch();
  const oid = useAppSelector((state) => state.orgDetails.data?.oid);
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);

  return (
    <Box className={classes.cardBox} minHeight={forMobile ? 'auto' : '240px'}>
      <Box p={2}>
        <Typography variant="h5">{loading ? <MySkeleton width={150} /> : `Getting Started`}</Typography>
      </Box>
      <Divider />
      <Box p={2} display="flex" flexDirection="column" >
      {checkOrfium(oid, selectedGroupId?.id) ? (
        helpfulTips.length === 0 ? (
          <>
            <Typography textAlign="center" pt={forMobile ? 0 : 5}>
              No Data Found
            </Typography>
          </>
        ) : (
          helpfulTips.map((ele: any, index: any) => {
            return (
              <React.Fragment key={ele._id}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  className="cursor-pointer"
                  onClick={() => handelOnClickPreviewArticleName(ele._id, ele.location)}
                >
                  <Typography noWrap variant="subtitle1" color="primary">
                    {ele?.articleName}
                  </Typography>
                  <ChevronRightIcon sx={{ color: '#ABB5BE' }} />
                </Box>
                {index !== helpfulTips.length - 1 && <Divider sx={{ my: 0.5 }} />}
              </React.Fragment>
            );
          })
        )
      ) : (
        (!cardContent || cardContent.length === 0) ? (
          <>
            <Typography textAlign="center" pt={{ sm: 4 }}>
              No data found
            </Typography>
          </>
        ) : (
          cardContent.map((item, index) => {
            return (
              <Box
                gap={1}
                key={index}
                display="flex"
                alignItems="center"
                onClick={() => {
                  setSelectedStep(index);
                  handleClickGettingStartedModal(true);
                }}
                className="cursor-pointer"
              >
                {loading ? (
                  <MySkeleton variant="rectangular" height={20} width={20} />
                ) : (
                  item.icon
                )}
                <Typography noWrap variant="subtitle1" color={appColors.blue1}>
                  {loading ? <MySkeleton width={250} /> : item.text}
                </Typography>
              </Box>
            );
          })
        )
      )}
    </Box>
  </Box>
  );
};

export default GettingStartedCard;
