import {
  Grid,
  Chip,
  Checkbox,
  TextField,
  InputLabel,
  FormControl,
  Autocomplete,
  FormHelperText,
  FormControlLabel,
  createFilterOptions,
  DialogContent,
  DialogContentText,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Divider
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { menteeTaskSlicAction } from '../../../../redux/slices/user-task/userMenteeTask';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AddLevels, AllLevels, EditLevel } from '../goals/components/AddLevels';
import { useEffect, useState } from 'react';
import AddTask from '../goals/components/AddTask';
import CommonModalHeader from '../../../../components/DialogUi/CommonModalHeader';
import { API } from '../../../../api';
import { getUserDetails } from '../../../../utils/orgName';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../../../queries/queryKeys';
import { taskActions } from '../../../../redux/slices/goals-tasks/getTasksSlice';
import useAllGroupTasks from '../../../../queries/useAllGroupTasks';

interface Task {
  taskName: any;
  taskDescription: any;
  tags: any;
}

interface TaskData {
  levelName: any;
  checkWithMentor: boolean;
  tasks: Task[];
}

export default function MenteeTaskPopup({ setReloadFlag }: any) {
  const navigate = useNavigate();
  const [addedTaskCount, setAddedTaskCount] = useState(0);
  const { levelId, taskId } = useParams();
  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useAppDispatch();
  const { isOpen, mode, selectedLevel, selectedLevelId } = useAppSelector((state) => state.userMenteeTask);
  const selectedMenteeId = useAppSelector((state) => state.goalTasks.userId);
  // console.log(selectedMenteeId, 'mentee id');
  // console.log(selectedLevel, 'level');
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  const groupId = selectedGroupId.id || '';
  const { data: menteeTask } = useAllGroupTasks(groupId, selectedMenteeId);
  const allTask = menteeTask?.data?.data;
  const queryClient = useQueryClient();

  let allLevels = [];
  if (allTask?.length) {
    allLevels = allTask[0].levels;
  }
  const [deletingIds, setDeletingIds] = useState<string[]>([]);
  const firstLevel = _.head(_.get(_.head(allTask), 'levels', [``]));
  const firstLevelId = _.get(firstLevel, '_id', '');
  const firstTaskId = _.get(_.head(_.get(firstLevel, 'tasks', [])), '_id', '');
  // console.log({ firstLevelId, firstTaskId });
  const matchedLevel = allLevels.find((level: any) => level._id === selectedLevelId);
  const matchedLevelTasks = matchedLevel?.tasks;
  // console.log(matchedLevelTasks, 'all matched taks');
  // console.log(selectedLevelId, 'selected level id');

  const resetFlow = () => {
    // reset();
    resetForm();
    reset();
    dispatch(menteeTaskSlicAction.setSelectedLevel({ selectedLevel: '', selectedLevelId: '' }));
    dispatch(menteeTaskSlicAction.openDialog({ isOpen: false, mode: '' }));
    setReloadFlag(true);
  };

  // const schema = yup.object({
  //   levelName: yup
  //     .string()
  //     .required('Level name is required')
  //     .test('no-empty-spaces', 'Level name cannot be all empty spaces', (value) => {
  //       if (value) {
  //         return !/^[\s]+$/.test(value);
  //       }
  //       return true;
  //     })
  // });

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
    getValues,
    setValue
  } = useForm({
    // resolver: yupResolver(schema)
  });

  const schemaArray = yup.object().shape({
    levelName: yup
      .string()
      .required('Level name is required')
      .test('no-empty-spaces', 'Level name cannot be all empty spaces', (value) => {
        if (value) {
          return !/^[\s]+$/.test(value);
        }
        return true;
      }),
    test: yup.array().of(
      yup.object().shape({
        taskName: yup.string().required('Lesson name is required'),
        taskDescription: yup.string().required('Lesson description is required'),
        tags: yup
          .array()
          .min(1, 'At least one tag is required')
          .of(yup.string())
          .required('At least one tag is required and should be from allowed tags') // Ensure tags array is required
      })
    )
  });
  // task
  const formArray = useForm({ resolver: yupResolver(schemaArray) });
  const formArrayFields = useFieldArray({
    control: formArray.control,
    name: 'test'
  });
  const resetForm = () => {
    formArray.reset(); // This will reset the entire form, including the array fields
    formArrayFields.remove(Array.from({ length: formArrayFields?.fields?.length }, (value, idx) => idx));
  };

  useEffect(() => {
    formArray.setValue('levelName', selectedLevel);
  }, [selectedLevel]);

  const handleCancel = () => {
    setDeletingIds([]);
    formArray.reset();
    formArray.reset({
      levelName: '' // Reset the 'levelName' field to an empty string
    });
    formArrayFields.remove(Array.from({ length: formArrayFields?.fields?.length }, (value, idx) => idx));
    setIsLoader(false);
    setAddedTaskCount(0);
    dispatch(menteeTaskSlicAction.openDialog({ isOpen: false, mode: 'add' }));
    dispatch(menteeTaskSlicAction.setSelectedLevel({ selectedLevel: '', selectedLevelId: '' }));
    reset();
    setReloadFlag(true);
  };
  const errorArray = formArray.formState.errors;
  // console.log(errorArray, 'errror array');

  const checkError = (fieldName: string) => Boolean(errors[fieldName]);
  const getError = (fieldName: string) => errors[fieldName]?.message;
  const onSubmit = (data: any) => {
    // delete level
    if (mode === 'delete') {
      setIsLoader(true);
      goalLevelDelete(groupId, getUserDetails().location, selectedLevelId)
        .then((res) => {
          // console.log(levelId, 'level id in url');
          if (levelId && levelId === selectedLevelId) {
            resetFlow();
            navigate(`/app/home/mentee/level/${firstLevelId}/task/${firstTaskId}`);
          } else {
            resetFlow();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // enters validation codes
      formArray.handleSubmit(async (values) => {
        // console.log(_.size(errorArray), errorArray);
        if (_.size(errorArray) > 0) {
          return false;
        } else {
          // it passes the validation
          setIsLoader(true);
          const allTask = formArray.watch('test');
          // console.log(allTask, 'newly added tasks');
          const levelName = formArray.watch('levelName');
          // console.log(levelName, 'levelName');
          // 1. Edit
          if (mode === 'edit' || mode === 'addTask') {
            const finalAllTask = allTask.map((task: any) => ({
              taskName: task.taskName,
              taskDescription: task.taskDescription,
              tags: task.tags,
              ...(task.taskId && { id: task.taskId })
            }));

            const editstructuredData = {
              levelName: levelName,
              checkWithMentor: true,
              tasks: finalAllTask
            };
            await API.goalTaskBulkDelete(
              groupId,
              getUserDetails().location,
              selectedLevelId,
              deletingIds,
              selectedMenteeId
            )
              .then(async (res) => {
                await editTask(editstructuredData, getUserDetails().location, groupId, selectedLevelId)
                  .then((res) => {
                    if (taskId && deletingIds?.includes(taskId)) {
                      resetFlow();
                      navigate(`/app/home/mentee/level/${firstLevelId}/task/${firstTaskId}`);
                    } else {
                      // mentee page
                      resetFlow();
                    }
                  })
                  .catch((err) => {
                    toast.error('Lesson failed to update');
                  })
                  .finally(() => {
                    setIsLoader(false);
                  });
              })
              .catch((err) => {
                setIsLoader(false);
                toast.error('Lesson failed to delete');
              })
              .finally(() => {
                console.log('executed bulk delete task'); // for identify api call is processed
              });
          }

          // 2. add task
          // if (mode === 'addTask') {
          //   const transformedData = {
          //     tasks: allTask.map((item: any) => ({
          //       taskName: item.taskName, // Modify the taskName as needed
          //       taskDescription: item.taskDescription,
          //       tags: item.tags
          //     }))
          //   };
          //   // console.log(transformedData, 'add task');
          //   addTaskToLevel(transformedData, getUserDetails().location, groupId, selectedLevelId);
          // }

          // 3. add level
          if (mode === 'add' && levelName) {
            const structuredData = {
              levelName: levelName,
              checkWithMentor: true,
              tasks: allTask.map((task: any) => ({
                taskName: task.taskName,
                taskDescription: task.taskDescription,
                tags: task.tags
              }))
            };
            // console.log(structuredData, 'add level');
            createTask(structuredData, getUserDetails().location, groupId);
          }
        }
      })();
    }
  };

  async function createTask(
    structuredData: {
      levelName: any;
      checkWithMentor: boolean; // Assuming a static value for checkWithMentor
      tasks: { taskName: any; taskDescription: any; tags: any }[];
    },
    location: string,
    groupId: any
  ) {
    try {
      const response = await API.createTask(structuredData, location, groupId, selectedMenteeId);
      // Handle the response or perform additional logic here
      // console.log('Level created successfully:', response);
      queryClient.setQueryData([queryKeys.GET_ALL_GROUP_TASKS, groupId, selectedMenteeId], (prev: any) => {
        // return [];
        const newData = prev.data.data[0];
        const oldLevels = prev.data.data[0].levels;
        dispatch(taskActions.updateTask([...oldLevels, response.data.response]));
        return {
          ...prev,
          data: { ...prev.data, data: [{ ...newData, levels: [...oldLevels, response.data.response] }] }
        };
      });
      toast.success('Level created successfully');
      setIsLoader(false);
      handleCancel();
      resetFlow();
      return response; // Optionally return the response
    } catch (error) {
      // Handle errors
      setIsLoader(false);
      console.error('Error creating task:', error);
      throw error; // Propagate the error if needed
    }
  }

  async function editTask(taskArray: any, location: string, groupId: any, levelId: any) {
    try {
      const response = await API.editTask(taskArray, location, groupId, levelId, selectedMenteeId);
      // Handle the response or perform additional logic here
      if (response.status === 200 && response.statusText === 'OK') {
        queryClient.setQueryData([queryKeys.GET_ALL_GROUP_TASKS, groupId, selectedMenteeId], (prev: any) => {
          // return [];
          const newData = prev.data.data[0];
          const newLevels = prev.data.data[0].levels.map((each: any) => {
            if (each._id === levelId) {
              return response.data.response;
            }
            return each;
          });
          dispatch(taskActions.updateTask(newLevels));

          return {
            ...prev,
            data: { ...prev.data, data: [{ ...newData, levels: newLevels }] }
          };
        });
      }
      handleCancel();
      resetFlow();
      if (mode === 'addTask') {
        toast.success('Lesson added successfully');
      } else {
        toast.success('Lesson edited successfully');
      }
      return response; // Optionally return the response
    } catch (error) {
      // Handle errors
      setIsLoader(false);
      throw error; // Propagate the error if needed
    }
  }

  async function addTaskToLevel(taskArray: any, location: string, groupId: any, levelId: any) {
    try {
      const response = await API.addTaskToLevel(taskArray, location, groupId, levelId);
      // Handle the response or perform additional logic here
      // console.log('task added to level successfully:', response);
      toast.success('Lesson added successfully');
      setIsLoader(false);
      resetFlow();
      return response; // Optionally return the response
    } catch (error) {
      // Handle errors
      setIsLoader(false);
      throw error; // Propagate the error if needed
    }
  }

  async function goalLevelDelete(groupId: any, location: string, levelId: any) {
    try {
      const response = await API.goalLevelDelete(groupId, location, levelId, selectedMenteeId);
      if (response.status === 200 && response.statusText) {
        queryClient.setQueryData([queryKeys.GET_ALL_GROUP_TASKS, groupId, selectedMenteeId], (prev: any) => {
          // return [];
          const newData = prev.data.data[0];
          const newLevels = prev.data.data[0].levels.filter((each: any) => each._id !== levelId);
          dispatch(taskActions.updateTask(newLevels));

          return {
            ...prev,
            data: { ...prev.data, data: [{ ...newData, levels: newLevels }] }
          };
        });
      }
      setIsLoader(false);
      // resetFlow();
      return response; // Optionally return the response
    } catch (error) {
      // Handle errors
      setIsLoader(false);
      throw error; // Propagate the error if needed
    }
  }

  const addAndEditLevels = () => {
    if (mode === 'add') {
      return (
        <DialogContent sx={{ padding: '20px' }}>
          <AddLevels
            setValue={setValue}
            formArrayControl={formArray.control}
            formArrayFields={formArrayFields}
            formArrayErrors={formArray.formState.errors}
          />
        </DialogContent>
      );
    } else if (mode === 'edit' || mode === 'addTask') {
      return (
        <DialogContent sx={{ padding: '20px' }}>
          <EditLevel
            selectedLevelId={selectedLevelId}
            selectedName={selectedLevel}
            setIsLoader={setIsLoader}
            resetFlow={resetFlow}
            formArrayControl={formArray.control}
            formArrayFields={formArrayFields}
            formArrayErrors={formArray.formState.errors}
            setDeletingIds={setDeletingIds}
          />
        </DialogContent>
      );
    } else if (mode === 'delete') {
      return (
        <DialogContent>
          <Typography variant="h5" textAlign="center">
            Are you sure you want to delete this <br /> level?
          </Typography>
        </DialogContent>
      );
    }
    // else if (mode === 'addTask') {
    //   return (
    //     <DialogContent>
    //       {/* <Divider /> */}
    //       <AddLevels
    //         control={control}
    //         checkError={checkError}
    //         getError={getError}
    //         getValues={getValues}
    //         setAddedTaskCount={setAddedTaskCount}
    //         selectedName={selectedLevel}
    //         formArrayControl={formArray.control}
    //         formArrayFields={formArrayFields}
    //         formArrayErrors={formArray.formState.errors}
    //       />
    //       {/* <AddTask control={control} checkError={checkError} getError={getError} getValues={getValues} /> */}
    //     </DialogContent>
    //   );
    // }
    else {
      return null;
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <CommonModalHeader
        handleCloseModal={handleCancel}
        title={
          mode === 'add'
            ? 'Add Level'
            : mode === 'edit'
              ? 'Edit Level'
              : mode === 'delete'
                ? 'Confirmation'
                : 'Add Lesson'
        }
      />

      {addAndEditLevels()}
      <DialogActions>
        {!isLoader && (
          <Button fullWidth variant="outlined" color="secondary" onClick={handleCancel}>
            {mode === 'delete' ? 'No' : 'Cancel'}
          </Button>
        )}
        {/* <Button onClick={handleSubmit(onSubmit)} color="primary" variant="contained" fullWidth> */}
        <LoadingButton
          loading={isLoader}
          fullWidth
          disabled={isLoader}
          onClick={onSubmit}
          color="primary"
          variant="contained"
        >
          {mode === 'delete' ? 'Yes, Delete' : 'Save'}
        </LoadingButton>
        {/* <Button onClick={onSubmit} color="primary" variant="contained" fullWidth>
          {mode === 'delete' ? 'Yes, Delete' : 'Save'}
        </Button> */}
      </DialogActions>
    </Dialog>
  );
}
